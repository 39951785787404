import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ClickAwayListener, List, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, Typography, Grid } from '@mui/material';

// assets
import { IconTool, IconHexagon3d, IconAdjustmentsHorizontal, IconCreditCard, IconApiApp } from '@tabler/icons-react';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import userTypes from 'constants/userTypes';

// ==============================|| SETTINGS MENU ||============================== //

const SettingsSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const userData = useSelector((state) => state.user?.data);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box ref={anchorRef} style={{ cursor: 'pointer' }} onClick={handleToggle}>
        <IconAdjustmentsHorizontal color="#1c1463" size={28} stroke={1.5} />
      </Box>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Grid style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 1 }}>
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 300,
                          minWidth: 250,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >
                        {userData?.type === userTypes.Admin && (
                          <>
                            <ListItemButton
                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                              onClick={() => {
                                navigate('/manage-app-configurations');
                                setOpen(false);
                              }}
                            >
                              <ListItemIcon>
                                <IconTool stroke={1.5} size="1.3rem" />
                              </ListItemIcon>
                              <ListItemText primary={<Typography variant="body2">App Configurations</Typography>} />
                            </ListItemButton>
                            <ListItemButton
                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                              onClick={() => {
                                navigate('/manage-integrations');
                                setOpen(false);
                              }}
                            >
                              <ListItemIcon>
                                <IconHexagon3d stroke={1.5} size="1.3rem" />
                              </ListItemIcon>
                              <ListItemText primary={<Typography variant="body2">App Integrations</Typography>} />
                            </ListItemButton>
                            <ListItemButton
                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                              onClick={() => {
                                navigate('/manage-billing');
                                setOpen(false);
                              }}
                            >
                              <ListItemIcon>
                                <IconCreditCard stroke={1.5} size="1.3rem" />
                              </ListItemIcon>
                              <ListItemText primary={<Typography variant="body2">Billing</Typography>} />
                            </ListItemButton>
                          </>
                        )}
                      </List>
                    </Box>
                  </Grid>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default SettingsSection;
