// react-router-dom
import { useRoutes } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import AdminRoutes from './AdminRoutes';
import SurveyRoutes from './SurveyRoutes';
import ManagerRoutes from './ManagerRoutes';
import PublicBookingRoutes from './PublicBookingRoutes';
import PublicApiRoutes from './PublicApiRoutes';

// constants
import userTypes from 'constants/userTypes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes({ isAuthenticated }) {
  const userData = useSelector((state) => state.user.data);
  const appConfig = useSelector((state) => state.appConfig?.data);
  const { anonymousSurveys: anonymousSurveysEnabled } = appConfig?.others || {};

  // public routes
  let routes = [AuthenticationRoutes, PublicBookingRoutes, PublicApiRoutes];

  // check if anonymous survey feature is enabled
  if (anonymousSurveysEnabled) {
    routes = [...routes, SurveyRoutes];
  }

  if (isAuthenticated) {
    if (userData.type === userTypes.Admin) {
      routes = [AdminRoutes, ...routes];
    } else if (userData.type === userTypes.Manager || userTypes.ExternalUser) {
      routes = [ManagerRoutes, ...routes];
    } else {
      routes = [...routes];
    }
  }

  return useRoutes(routes);
}
