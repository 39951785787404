import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const ApiView = Loadable(lazy(() => import('views/api/ApiView')));
const SurveyPdfView = Loadable(lazy(() => import('views/api/SurveyPdfView')));

// ==============================|| Public Booking ROUTING ||============================== //

const PublicApiRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/api',
      element: <ApiView />
    },
    {
      path: '/api/v1/survey-pdf',
      element: <SurveyPdfView />
    }
  ]
};

export default PublicApiRoutes;
